<template>
  <section class="uk-section uk-section-default bgt" :id="id" v-if="content" :style="content.style">
    <div class="uk-container bg">
        <p><router-link to="/" class="uk-button uk-button-primary" uk-icon="icon: arrow-left">Zurück zur Hauptseite</router-link></p>
        <h2>{{ content.title }}</h2>
        <p><img class="uk-animation-fade" width="200" height="66.6" src="../assets/img/logo.svg" alt=""></p>
        <article class="uk-article" v-for="p in content.parts" :key="p.id">
            <h3 v-if="p.subtitle">{{ p. subtitle }}</h3>
            <div v-if="p.text" v-html="p.text"></div>
            <a class="uk-float-right" href="" uk-tooltip="zurück nach oben" uk-totop uk-scroll=""></a>
        </article>
        <hr class="uk-divider-icon">
        <p><router-link to="/" class="uk-button uk-button-primary" uk-icon="icon: arrow-left">Zurück zur Hauptseite</router-link></p>
        <div class="uk-clearfix"></div>
    </div>
  </section>
</template>
<script>

import axios from 'axios'

export default {
    name: 'Impressum',
    data () {
        return {
        id: 'impressum',
        content: null,
        error: null
        }
    },
    methods: {
        pageInit: function () {
        axios.get(this.$baseUrl + this.id)
            .then( (res) => {
            this.content = res.data
            })
            .catch( (error) => {
            this.error = error
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.pageInit()
        })
    }
}
</script>
<style>
</style>